'use strict';

/*
 * The vast majority of the code exported by this module is a direct copy of the code from
 * the culori package (see https://culorijs.org/), which deserves full credit for it.
 */

import oklab from './oklab';

export default {
  oklab,
};
